import React from 'react';
import StarRatings from 'react-star-ratings';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { colors } from '../utils'
import Fond07 from '../svg/fond_07.svg';
import RecoIllu from '../svg/reco_illu.svg';

export default ({ dims }) => {
  /*const goToSignup = () => {
    window.open('https://app.expliquemoipythagore.fr/register', '_blank');
  }*/
  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: dims.x > 1200 ? 'transparent' : '#D9E9F4', marginTop: dims.x > 1200 ? 100 : 50 }}>
      <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: 'column', marginTop: dims.x > 1200 ? 200 : 50 }}>
        <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, fontFamily: 'poppins', textAlign: dims.x > 1200 ? 'left' : 'center' }}>ILS NOUS RECOMMANDENT</h2>
        <div style={{ width: '100%', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', alignItems: 'center', justifyContent: 'space-between', marginTop: 50 }}>
          <div style={{ width: dims.x > 1200 ? 390 : '100%', height: dims.x > 1200 ? 430 : 350, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {dims.x > 1200 && <p style={{ fontFamily: 'poppins', color: colors.dark_blue, textTransform: 'uppercase' }}>parents</p>}
            <div style={{ width: dims.x > 1200 ? 390 : '75%', height: dims.x > 1200 ? 'auto' : 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: `-15px 15px 0px 0px rgba(0, 80, 150, 0.1)` }}>
              {false && <div style={{ width: 90, height: 90, borderRadius: 50, marginTop: 10, backgroundColor: colors.button_blue }}></div>}
              <p style={{ fontWeight: 'bold', fontFamily: 'poppins', fontSize: '1em', marginTop: 15, marginBottom: 10 }}>Jacques M.</p>
              <StarRatings
                rating={4.7}
                starWidthAndHeight="30"
                starRatedColor={colors.button_blue}
                starEmptyColor={colors.bg}
              />
              <p style={{ color: colors.gray, fontFamily: 'poppins', fontSize: '0.8em', width: '90%', marginTop: 10 }}>Les professeurs sont recrutés individuellement par l'équipe d'EMP, et c'est très rassurant. En effet notre fille avait besoin d'être écoutée et accompagnée sur le long terme, et nous sommes ravis de la relation de confiance qui s'est installée entre elle et sa professeur</p>
            </div>
          </div>
          <div style={{ width: dims.x > 1200 ? 390 : '100%', height: dims.x > 1200 ? 430 : 350, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {dims.x > 1200 && <p style={{ fontFamily: 'poppins', color: colors.dark_blue, textTransform: 'uppercase' }}>élèves</p>}
            <div style={{ width: dims.x > 1200 ? 390 : '75%', height: dims.x > 1200 ? 'auto' : 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: `-15px 15px 0px 0px rgba(0, 80, 150, 0.1)` }}>
              {false && <div style={{ width: 90, height: 90, borderRadius: 50, marginTop: 10, backgroundColor: colors.button_blue }}></div>}
              <p style={{ fontWeight: 'bold', fontFamily: 'poppins', fontSize: '1em', marginTop: 15, marginBottom: 10 }}>Jules D.</p>
              <StarRatings
                rating={4.5}
                starWidthAndHeight="30"
                starRatedColor={colors.button_blue}
                starEmptyColor={colors.bg}
              />
                <p style={{ color: colors.gray, fontFamily: 'poppins', fontSize: '0.8em', width: '90%', marginTop: 10 }}>L'équipe d'Explique-Moi Pythagore a su trouver en un délai record le professeur parfait pour répondre à mon besoin urgent, et mes résultats s'en font ressentir</p>
            </div>
          </div>
          <div style={{ width: dims.x > 1200 ? 390 : '100%', height: dims.x > 1200 ? 430 : 350, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {dims.x > 1200 && <p style={{ fontFamily: 'poppins', color: colors.dark_blue, textTransform: 'uppercase' }}>PROFESSEURS</p>}
            <div style={{ width: dims.x > 1200 ? 390 : '75%', height: dims.x > 1200 ? 'auto' : 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: `-15px 15px 0px 0px rgba(0, 80, 150, 0.1)` }}>
              {false && <div style={{ width: 90, height: 90, borderRadius: 50, marginTop: 10, backgroundColor: colors.button_blue }}></div>}
              <p style={{ fontWeight: 'bold', fontFamily: 'poppins', fontSize: '1em', marginTop: 15, marginBottom: 10 }}>Cindy A.</p>
              <StarRatings
                rating={5}
                starWidthAndHeight="30"
                starRatedColor={colors.button_blue}
                starEmptyColor={colors.bg}
              />
              <p style={{ color: colors.gray, fontFamily: 'poppins', fontSize: '0.8em', width: '90%', marginTop: 10 }}>Grâce à EMP je peux choisir les cours en fonction de mon emploi du temps, pour venir en aide à des élèves qui souhaiteraient bénéficier d’une approche pédagogique différente. C’est une application fonctionnelle et facile d’utilisation !</p>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <a href="https://app.expliquemoipythagore.fr/register" target="_blank" style={{ width: dims.x > 1200 ? 400 : '80%', height: dims.x > 1200 ? 60 : 60, backgroundColor: colors.blue, display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginTop: dims.x > 1200 ? 0 : 0, marginBottom: dims.x > 1200 ? 0 : 60 }}>
            <p style={{ margin: 0, color: colors.white, fontWeight: 450, fontFamily: 'poppins', fontSize: dims.x > 1200 ? 25 : 20, textAlign: 'center' }}>COMMANDER UN COURS</p>
          </a>
        </div>
        {dims.x > 1200 && <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 50 }}>
          <div style={{ marginLeft: 100, marginRight: 70 }}>
            <RecoIllu />
          </div>
          <CircularProgressbar value={97} text={`97%`} styles={{
            root: {
              width: 200,
            },
            path: {
              stroke: colors.blue,
              strokeLinecap: 'butt',
            },
            text: {
              fontFamily: 'poppins',
              fill: colors.dark_blue,
              fontWeight: 'bold',
              fontSize: '1.5em',
            }
          }}/>
          <p style={{ fontFamily: 'poppins', color: colors.dark_blue, fontSize: 50, lineHeight: 1, marginLeft: 50 }}>DES FAMILLES<br/>NOUS<br/>RECOMMANDENT</p>
        </div>}
      </div>
      {dims.x > 1200 && <div style={{ width: '100%', position: 'absolute', right: 0, top: 0, zIndex: -2 }}>
        <Fond07 />
      </div>}
    </div>
  )
}
