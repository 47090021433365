import React from 'react'

import { colors } from '../utils'
import NotreApp from '../svg/notre_app.svg'
import AppStore from '../svg/app_store.svg'

export default ({ dims }) => {
  return (
    <div style={{ width: dims.x > 1200 ? 1200 : '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: dims.x > 1200 ? 'flex-start' : 'center', paddingLeft: dims.x > 1200 ? 50 : 0, marginBottom: dims.x > 1200 ? 400 : 50 }}>
      <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 25, color: colors.dark_blue, width: dims.x > 1200 ? 400 : '80%', fontFamily: 'poppins', textAlign: dims.x > 1200 ? 'left' : 'center' }}>NOTRE APPLICATION</h2>
      <div style={{ display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', width: 370, justifyContent: 'space-between', alignItems: 'center', marginLeft: '-14px' }}>
        <a rel="noreferrer" target="_blank" href='https://play.google.com/store/apps/details?id=com.empythagore.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{ height: '80px', marginBottom: 0 }} alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/></a>
        <a rel="noreferrer" target="_blank" href='https://apps.apple.com/fr/app/explique-moi-pythagore/id1435151757'>
          <AppStore style={{ transform: 'scale(1.35)' }}/>
        </a>
      </div>
      {dims.x > 1200 ? (
        <video controls width="270" height="585" style={{ position: 'absolute', right: 200, top: 20 }}>
          <source src="https://api.expliquemoipythagore.fr/v2/public/demo-video"
                  type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <video controls width="190" height="420" style={{ marginTop: 50, right: 77, top: 120 }}>
          <source src="https://api.expliquemoipythagore.fr/v2/public/demo-video"
                  type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
      )}
    </div>
  )
}
