import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475 150">
    <title>L</title>
    <rect style={{fill: "#fff"}} x="45.51" y="23.08" width="376.63" height="104.14"/>
    <path style={{fill: "#373cf1"}}
          d="M399.16,1.16H75.84A73.84,73.84,0,0,0,2,75H2a73.84,73.84,0,0,0,73.84,73.84H399.16A73.84,73.84,0,0,0,473,75h0A73.84,73.84,0,0,0,399.16,1.16Zm-296,118.45A44.15,44.15,0,1,1,120.76,35l-5.55,12.87a29.8,29.8,0,0,0-12.09-2.56A30.15,30.15,0,1,0,121,99.84l9.31,10.4A43.73,43.73,0,0,1,103.12,119.61Zm0-34.62a9.65,9.65,0,0,0,3.71-.78c.77.84,9.51,10.59,9.51,10.59A23.11,23.11,0,0,1,103.12,99a23.53,23.53,0,1,1,0-47,23.88,23.88,0,0,1,9.43,2L107,66.63a9.55,9.55,0,0,0-13.44,8.83A9.54,9.54,0,0,0,103.12,85ZM128,98.24l-9.45-10.41a21.37,21.37,0,0,0,4.35-12.37,20.81,20.81,0,0,0-2.27-9.15l12.7-5.92a34.06,34.06,0,0,1,3.56,15.07A33.7,33.7,0,0,1,128,98.24ZM116.75,61.09l-.08-.07,10.74-24.1a45.31,45.31,0,0,1,13.31,12.94ZM184.44,93c-9.23,0-15.39-6.21-15.39-17.72,0-11.26,6.55-17.57,15.29-17.57a14.76,14.76,0,0,1,10.24,3.69l-3.79,4.51a9.69,9.69,0,0,0-6.06-2.33c-4.32,0-7.33,3.45-7.33,11.7s3,11.56,7.42,11.56c2.67,0,4.62-1.21,6.61-2.72L195,88.71A14.87,14.87,0,0,1,184.44,93Zm26.69,0c-9.22,0-15.39-6.21-15.39-17.72,0-11.26,6.56-17.57,15.3-17.57a14.76,14.76,0,0,1,10.24,3.69l-3.79,4.51a9.69,9.69,0,0,0-6.06-2.33c-4.33,0-7.34,3.45-7.34,11.7s3,11.56,7.43,11.56c2.67,0,4.61-1.21,6.6-2.72l3.55,4.56A14.89,14.89,0,0,1,211.13,93Zm21.8-.82h-8V58.56h8Zm36.5-28H258.17v8.93H268v5.68h-9.81V92.21h-8V58.56h20Zm51.6,28L319.29,85h-9.81l-1.7,7.23H291.57l-6.89-12.87h-3.11V92.21h-8V58.56h11c9.12,0,13.83,3.26,13.83,10.25,0,4.42-2.08,7.09-6.45,9.08l7.86,13.18,9.8-32.51h9.66l10.15,33.65Zm36.61,0H347.49l-9.71-26.07a98.78,98.78,0,0,1,1,13.74V92.21h-7.09V58.56h9.91l9.95,26.12a77.25,77.25,0,0,1-1.07-12.52V58.56h7.14Zm19.85.82c-9.23,0-15.39-6.21-15.39-17.72,0-11.26,6.55-17.57,15.29-17.57a14.76,14.76,0,0,1,10.24,3.69l-3.78,4.51a9.73,9.73,0,0,0-6.07-2.33c-4.32,0-7.33,3.45-7.33,11.7s3,11.56,7.43,11.56c2.67,0,4.61-1.21,6.6-2.72L388,88.71A14.87,14.87,0,0,1,377.49,93ZM409.62,72.3v5.59H399.28v8.59H412v5.73H391.32V58.56H412l-.82,5.69H399.28v8Z"/>
    <polygon style={{fill: "#33f"}} points="310.84 79.2 317.93 79.2 314.38 64.34 310.84 79.2"/>
    <path style={{fill: "#33f"}}
          d="M284.43,64.15h-2.86v9.71h3.35c3.49,0,5.34-1.51,5.34-5C290.26,65.56,288.37,64.15,284.43,64.15Z"/>
  </svg>
}
