import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { colors } from '../utils';

const Picker = (props) => (
  <Select
    onChange={props.onChange}
    value={props.value}
    options={props.options}
    styles={{
      container: (base) => ({
        ...base,
        width: '100%',
        height: props.dims.x > 1200 ? '35%' : '100%',
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
        borderRadius: 5,
        backgroundColor: colors.button_blue,
        marginBottom: '2.5%',
        padding: 10
      }),
      control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        backgroundColor: colors.button_blue,
        height: '100%',
        fontFamily: 'Poppins',
      }),
      singleValue: (base) => ({
        ...base,
        color: colors.white,
        fontSize: props.dims.x > 1200 ? 20 : 14,
      }),
      option: (base) => ({
        ...base,
        fontFamily: 'Poppins',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        display: 'none'
      }),
      indicatorsContainer: (base) => ({
        ...base,
        display: 'none'
      }),
      placeholder: (base) => ({
        ...base,
        color: '#ddd',
        fontFamily: 'Poppins'
      }),
      noOptionsMessage: (base) => ({
        ...base,
        fontFamily: 'Poppins'
      })
    }}
    placeholder={props.placeholder}
    noOptionsMessage={() => 'Aucun résultat'}
  />
)

export default ({ dims }) => {

  const [ remote, setRemote] = useState(false);
  const [ topics, setTopics ] = useState([]);
  const [ levels, setLevels ] = useState([]);
  const [ topic, setTopic ] = useState(null);
  const [ level, setLevel ] = useState(null);

  const [ price, setPrice ] = useState(null);

  const fetchData = async () => {
    try {
      const levelsRes = await fetch('https://api.expliquemoipythagore.fr/v2/public/levels');
      setLevels(await levelsRes.json());
      const topicsRes = await fetch('https://api.expliquemoipythagore.fr/v2/public/topics');
      setTopics(await topicsRes.json());
    } catch (err) {
      console.error('Error fetching levels', err);
    }
  }


  useEffect(() => {
    fetchData();
  }, []);

  const submit = () => {
    if (!level || !topic) return
    const l = levels.find(l => l._id === level.value)
    setPrice(remote ? l.remotePrice : l.price);
  }

  useEffect(submit, [ remote ])

  const goToSignup = () => {
    window.open('https://app.expliquemoipythagore.fr/register', '_blank');
  }

  return (
    <div style={{ width: dims.x > 1200 ? 1200 : '80%', marginTop: dims.x > 1200 ? 100 : 0 }}>
      <div style={{ display: 'flex', alignItems: 'flex-end', width: dims.x > 1200 ? 400 : '100%' }}>
        <div onClick={() => setRemote(false)} onKeyPress={() => setRemote(false)} role='button' tabIndex='-7' style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: remote ? colors.dark_blue : colors.medium_blue, width: '50%', height: remote ? 40 : 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          <h2 style={{ color: colors.white, fontWeight: 'bold', fontFamily: 'Poppins', fontSize: dims.x > 1200 ? 18 : 14, margin: 0, textAlign: 'center', padding: 5 }}>Cours à domicile</h2>
        </div>
        <div onClick={() => setRemote(true)} onKeyPress={() => setRemote(true)} role='button' tabIndex='-6' style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: !remote ? colors.dark_blue : colors.medium_blue, width: '50%', height: !remote ? 40 : 50, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          <h2 style={{ color: colors.white, fontWeight: 'bold', fontFamily: 'Poppins', fontSize: dims.x > 1200 ? 18 : 14, margin: 0, textAlign: 'center', padding: 5 }}>Cours à distance</h2>
        </div>
      </div>
      <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: 20, width: dims.x > 1200 ? 635 : '100%', minHeight: dims.x > 1200 ? 220 : 150, backgroundColor: colors.medium_blue, borderRadius: 5, borderTopLeftRadius: 0, borderTopRightRadius: dims.x > 1200 ? 5 : 0 }}>
        <Picker dims={dims} value={level} onChange={setLevel} options={levels.map((l) => ({ value: l._id, label: l.name }))} placeholder={'Niveau'}/>
        <Picker dims={dims} value={topic} onChange={setTopic} options={topics.map((l) => ({ value: l._id, label: l.name }))} placeholder={'Matière'}/>
        <div onClick={submit} onKeyPress={submit} role='button' tabIndex='-5' style={{ cursor: 'pointer', minWidth: '37%', height: '15%', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, outline: 'none' }}>
          <p style={{ margin: 0, color: colors.dark_blue, fontFamily: 'Poppins', fontWeight: 'bold', fontSize: dims.x > 1200 ? 12 : 11, padding: 5 }}>{price ? `${price}€`: 'Voir mon tarif'}</p>
        </div>
        {price && <div onClick={goToSignup} onKeyPress={goToSignup} role='button' tabIndex='-8' style={{ cursor: 'pointer', minWidth: '37%', height: '15%', backgroundColor: colors.green, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, outline: 'none', marginTop: 10 }}>
          <p style={{ margin: 0, color: colors.white, fontFamily: 'Poppins', fontWeight: 'bold', fontSize: dims.x > 1200 ? 12 : 11, padding: 5 }}>S'inscrire et commander un cours</p>
        </div>}
      </form>
    </div>
  )
}
