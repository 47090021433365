import React, { useState } from 'react'

import { colors } from '../utils'
import Fond03 from '../svg/fond_03.svg'
import RightArrow from '../svg/right-arrow.svg'
import Tuto1 from '../svg/tuto_1.svg'
import Tuto2 from '../svg/tuto_2.svg'
import Tuto3 from '../svg/tuto_3.svg'
import Cci from './cci'
import Idf from './idf'
import Ministere from './ministere'
import Pepite from './pepite'
import StationF from './stationf'

const steps = [
  'Sur notre application mobile ou sur notre site, commandez votre cours particulier, à domicile ou à distance, de la matière et du niveau de votre choix.',
  'Nous nous mettons à la recherche du professeur idéal pour vous. Dès que nous l\'aurons trouvé, vous recevrez une notification vous invitant à valider le professeur et confirmer ainsi le cours',
  'A l’issue du cours, le paiement se fait directement auprès du professeur, nous n’intervenons pas dans la transaction et ne prenons aucune commission sur le prix du cours : ainsi, les professeurs sont mieux payés, sans coût supplémentaire pour les parents',
]

export default ({ dims }) => {
  const [ step, setStep ] = useState(0);

  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: dims.x > 1200 ? 200 : 50 }}>
      {dims.x > 1200 && <div style={{ position: 'absolute', right: 0, top: -30, width: '100%', zIndex: -3 }}>
        <Fond03 width="100%" />
      </div>}
      <div style={{ width: '100%', maxWidth: 1200, height: dims.x > 1200 ? 100 : 'auto', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', alignItems: 'center', marginTop: dims.x > 1200 ? 110 : 40 }}>
        <div style={{ width: 120, marginRight: dims.x > 1200 ? 60 : 0, marginTop: dims.x > 1200 ? 0 : 40 }}>
          <Ministere/>
        </div>
        <div style={{ width: 120, marginRight: dims.x > 1200 ? 60 : 0, marginTop: dims.x > 1200 ? 0 : 40 }}>
          <Idf/>
        </div>
        <div style={{ width: 120, marginRight: dims.x > 1200 ? 60 : 0, marginTop: dims.x > 1200 ? 0 : 40 }}>
          <StationF/>
        </div>
        <div style={{ width: 120, marginRight: dims.x > 1200 ? 60 : 0, marginTop: dims.x > 1200 ? 0 : 40 }}>
          <Cci/>
        </div>
        <div style={{ width: 120, marginRight: dims.x > 1200 ? 60 : 0, marginTop: dims.x > 1200 ? 0 : 40 }}>
          <Pepite/>
        </div>
      </div>
      <div style={{ width: dims.x > 1200 ? 1200 : '100%', display: 'flex', flexDirection: 'row', justifyContent: dims.x > 1200 ? 'space-between' : 'space-between', alignItems: 'center', marginTop: dims.x > 1200 ? 180 : 50, backgroundColor: dims.x > 1200 ? 'transparent' : colors.blue, paddingTop: dims.x > 1200 ? 0 : 50, paddingBottom: dims.x > 1200 ? 0 : 50 }}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: dims.x > 1200 ? '10%' : '10%', cursor: 'pointer', transform: 'rotate(180deg)', marginLeft: dims.x > 1200 ? 45 : 10, outline: 'none' }}
          onClick={() => setStep(step > 0 ? (step - 1)  : 0)} onKeyPress={() => setStep(step > 0 ? (step - 1)  : 0)} role='button' tabIndex='-3'
        >
          <RightArrow />
        </div>
        {dims.x > 1200 && <div style={{ position: 'absolute', top: -20, left: 45, zIndex: -2 }}>
          {step === 0 ?
            <Tuto1 height="1100"/>
            : step === 1 ?
            <Tuto2 height="1100"/>
            :
            <Tuto3 height="1100"/>
          }
        </div>}
        {dims.x > 1200 && <div style={{ width: '35%' }}/>}
        <div style={{ marginLeft: dims.x > 1200 ? 35 : 0, width: dims.x > 1200 ? 500 : '75%' }}>
          <h2 style={{ color: colors.dark_blue, fontSize: dims.x > 1200 ? 49 : 30, width: dims.x > 1200 ? 400 : 'auto', textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center' }}>Comment ça marche ?</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 167, marginTop: dims.x > 1200 ? 50 : 50 }}>
            {dims.x > 1200 && <p style={{ textAlign: 'center', margin: 0, width: dims.x > 1200 ? 130 : '10%', fontSize: 180, color: 'white', fontFamily: 'poppins' }}>{step + 1}</p>}
            {dims.x > 1200 && <div style={{ backgroundColor: 'white', height: '100%', width: 2, marginRight: 35, marginLeft: 20 }}/>}
            <p style={{ color: 'white', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 12, maxWidth: dims.x > 1200 ? 450 : '70%', margin: 0, textAlign: dims.x > 1200 ? 'left' : 'center' }}>{steps[step]}</p>
          </div>
          <div style={{ marginTop: 105, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', marginRight: 20, backgroundColor: step === 0 ? 'white' : 'transparent' }}/>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', marginRight: 20, backgroundColor: step === 1 ? 'white' : 'transparent' }}/>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', backgroundColor: step === 2 ? 'white' : 'transparent' }}/>
          </div>
        </div>
        <div style={{ marginRight: dims.x > 1200 ? 45 : 10, cursor: 'pointer', outline: 'none' }} onClick={() => setStep(step < 2 ? (step + 1) : 2)} onKeyPress={() => setStep(step < 2 ? (step + 1) : 2)} role='button' tabIndex='-4'>
          <RightArrow />
        </div>
      </div>
    </div>
  )
}
