import React from 'react';

import { colors } from '../utils'
import Fond08 from '../svg/fond_08.svg';
import Corentin from './corentin';

export default ({ dims }) => {
  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -17 }}>
      <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column-reverse', alignItems: 'center', justifyContent: 'center', marginTop: dims.x > 1200 ? 200 : 50 }}>
        {dims.x > 1200 && <div style={{ width: dims.x > 1200 ? '50%' : '100%', justifyContent: 'flex-start', zIndex: -1 }}>
          <div style={{ backgroundColor: 'white', padding: 30, paddingBottom: 150, transform: 'scale(0.85) rotate(-5deg)', width: dims.x > 1200 ? 540 : '100%', marginTop: -60, boxShadow: '-4px 4px 4px 4px rgba(0, 0, 0, 0.2)' }}>
            <div style={{ height: dims.x > 1200 ? 480 : '90%', width: dims.x > 1200 ? 480 : '90%' }}>
              <Corentin />
            </div>
          </div>
        </div>}
        <div style={{ width: dims.x > 1200 ? '40%' : '80%' }}>
          <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, fontFamily: 'poppins', marginBottom: 50, textAlign: dims.x > 1200 ? 'left' : 'center' }}>NOS VALEURS</h2>
          <p style={{ fontFamily: 'poppins', color: dims.x > 1200 ? colors.white : colors.dark_blue, textAlign: dims.x > 1200 ? 'left' : 'center' }}>
            Nous ne laisserons jamais un élève décrocher scolairement, c'est pour cette raison que nous avons proposé les cours à distance durant toutes les périodes de confinement, sans contrepartie financière, et ce pour tous les élèves de France.
            <br/>
            <br/>
            Cette initiative de solidarité a été reconnue et soutenue par la ministre Frédérique Vidal et le ministère de l'enseignement supérieur, de la recherche et de l’innovation, qui nous a décerné le prix Pépites des PEPITE.
          </p>
        </div>
      </div>
      {dims.x > 1200 && <div style={{ width: '100%', position: 'absolute', right: 0, top: 0, zIndex: -2 }}>
        <Fond08 width={'100%'} height={'100%'}/>
      </div>}
    </div>
  )
}
