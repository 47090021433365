import React, { useEffect, useState } from "react"

import Header from "../components/header"
import SEO from "../components/seo"

import Eleve from '../svg/eleve_assis.svg'

import Form from '../components/form'
import Tuto from '../components/tuto'
import Application from '../components/application'
import Tarifs from '../components/tarifs'
import Professeurs from '../components/professeurs'
import Recos from '../components/recos'
import Values from '../components/values'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Background from '../svg/header.svg'
import Logo from '../svg/logo.svg'
import { Link } from 'gatsby'
import colors from "../../../Application/globals/colors";

export default () => {
  
  const [ dims, setDims ] = useState({ x: 1200, y: 1200 });
  const [ yScroll, setYScroll ] = useState(0);
  const [ height, setHeight ] = useState();
  
  useEffect(() => {
    setDims({ x: window.innerWidth, y: window.innerHeight })
    setHeight(window.document.body.getBoundingClientRect());
    window.addEventListener('resize', () => setDims({ x: window?.innerWidth || 0, y: window.innerHeight }));
    window.addEventListener('scroll', () => {
      setYScroll(window.pageYOffset);
      setDims({ x: window.innerWidth, y: window.innerHeight });
    })}, []);

  return (
    <>
      <Background width={'50%'} height={'100%'} style={{position: 'relative', zIndex: 501}}/>
      {dims.x > 800 &&
      <div style={{position: 'absolute', top: dims.x/50, left: dims.x/50, zIndex: 502}}>
        <Link to='/' aria-label='home'>
          <div style={dims.x > 1200 ? {transform: 'scale(1.2)'} : { transform: 'scale(0.7)' }}>
            <Logo />
          </div>
        </Link>
      </div>}
      <Header dims={dims} scroll={yScroll} height={height}/>
      <div style={styles.container}>
        <SEO />
        {dims.x > 1200 && <div style={{ position: 'absolute', top: -30, right: 0, zIndex: -1 }}>
          <Eleve />
        </div>}
        <Form dims={dims}/>
        <Tuto dims={dims}/>
        <Application dims={dims}/>
        <Tarifs dims={dims} id="tarifs"/>
        <Professeurs dims={dims}/>
        <Recos dims={dims}/>
        <Values dims={dims}/>
        <Contact dims={dims}/>
        <Footer dims={dims}/>
      </div>
    </>
  )
}

const styles ={
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}
