import React from 'react'

import { colors } from '../utils'
import Diplome from '../svg/PERSO_diplomé.svg';
import Prof from '../svg/PERSO_profs.svg';
import Retraite from '../svg/PERSO_retraité.svg';
import Salarie from '../svg/PERSO_salarié.svg';
import {Link} from "gatsby";

const profs = [
  { name: 'Diplomés', icon: Diplome },
  { name: 'Salariés', icon: Salarie },
  { name: 'Professeurs en activité', icon: Prof },
  { name: 'Professeurs retraités', icon: Retraite },
]

export default ({ dims }) => {
  return (
    <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10%' }}>
      <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, fontFamily: 'poppins', textAlign: 'center' }}>QUI SONT NOS PROFESSEURS ?</h2>
      <h3 style={{ textAlign: 'center', width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}>Tous nos professeurs sont recrutés et vérifiés :</h3>
      <h3 style={{ textAlign: 'left', width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}>Ils passent tous des tests techniques et pédagogiques, pour nous assurer de leurs compétences et de leur bonne transmission du savoir !</h3>
      <h3 style={{ textAlign: 'left', width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}>Nous leurs accréditons les matières et niveaux à l’issue :</h3>
      <h3 style={{ textAlign: 'left', paddingLeft: 16, width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}> - de leur tests technique et pédagogique,</h3>
      <h3 style={{ textAlign: 'left', paddingLeft: 16, width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}> - d'un entretien individuel,</h3>
      <h3 style={{ textAlign: 'left', paddingLeft: 16, width: dims.x > 1200 ? 600 : '80%', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, fontWeight: 'normal' }}> - et de la vérification de leurs casier judiciaire et diplômes.</h3>
      <div style={{ display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', width: dims.x > 1200 ? 725 : '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        {profs.map((prof) => (
          <div key={prof.name} style={{ width: dims.x > 1200 ? 150 : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', height: 320 }}>
            <prof.icon/>
            <div style={{ height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
              <p style={{ margin: 0, fontFamily: 'poppins', fontSize: 24.25, fontWeight: 300, textAlign: 'center', color: colors.gray }}>{prof.name}</p>
            </div>
          </div>
        ))}
      </div>
        <Link to='/teachers' style={{ width: dims.x > 1200 ? 487 : '80%', height: dims.x > 1200 ? 80 : 60, backgroundColor: colors.blue,
            display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginTop: dims.x > 1200 ? 40 : 60,
            margin: 0, color: colors.white, fontWeight: 450, fontFamily: 'poppins', fontSize: dims.x > 1200 ? 34 : 20, textAlign: 'center' }}>
        DEVENIR PROFESSEUR
      </Link>
    </div>
  )
}
