import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {

  return <svg version="1.1" id="Calque_2" x="0px" y="0px" width="100px" height="17.933px" viewBox="0 0 100 17.933" >
<path d="M97.534,6.974h-4.508v3.363h4.508V6.974z M100,0.249h-6.974v3.363H100V0.249z M92.528,0.249h-3.612v17.435h3.612V0.249z   M79.701,0.249h-3.612v7.846l1.245,3.263c0.623,1.644,1.295,2.341,2.366,2.341V0.249z M72.104,17.684V9.838l-1.245-3.313  c-0.623-1.644-1.295-2.291-2.366-2.291v13.45H72.104z M79.701,17.684v-3.487c-0.872,0-1.968-0.324-2.74-2.366L72.602,0.249h-4.11  v3.487c0.872,0,1.968,0.299,2.74,2.366l4.359,11.582H79.701z M59.989,14.571c-1.021,0-1.744-0.847-1.744-1.868V5.231  c0-1.021,0.722-1.868,1.744-1.868V0c-2.939,0-5.355,2.291-5.355,5.231v7.472c0,2.939,2.416,5.231,5.355,5.231V14.571z M65.842,5.231  C65.842,2.291,63.426,0,60.487,0v3.363c1.021,0,1.744,0.847,1.744,1.868v7.472c0,1.021-0.722,1.868-1.744,1.868v3.363  c2.939,0,5.355-2.291,5.355-5.231V5.231z M51.982,0.249H48.37v17.435h3.612V0.249z M46.143,0.249H35.159v3.363h10.984V0.249z   M42.457,4.11h-3.612v13.575h3.612V4.11z M30.669,15.542l-0.648-2.914h-2.192l1.121-4.832c-0.498-2.042-1.245-3.587-2.765-3.587  l-3.138,13.475h3.636l0.498-2.142H30.669z M35.301,17.684l-4.06-17.435h-4.135L26.31,3.736c1.046,0,2.341,0.623,3.039,3.711  l2.316,10.237H35.301z M23.244,0.249H12.26v3.363h10.984V0.249z M19.558,4.11h-3.612v13.575h3.612V4.11z M10.959,4.982  C10.835,1.993,8.643,0,5.853,0v3.363C6.7,3.387,7.348,4.135,7.348,5.231L10.959,4.982z M5.355,14.571  c-0.996-0.025-1.744-0.922-1.744-1.868L0,12.877c0.1,2.964,2.391,5.056,5.355,5.056V14.571z M5.853,17.933  c2.964,0,5.48-2.316,5.48-5.305c0-2.441-1.171-4.135-3.437-4.882C7.298,7.547,5.928,7.049,5.405,6.85  C4.608,6.551,3.836,6.077,3.836,5.031c0-1.146,0.822-1.644,1.519-1.669V0c-2.74,0-5.156,2.042-5.156,5.081  c0,2.018,1.021,4.085,3.612,4.982c0.623,0.224,1.295,0.473,2.067,0.772c1.046,0.399,1.719,0.996,1.719,1.918  c0,1.146-0.722,1.793-1.744,1.818V17.933z"/>
</svg>
}
