import React, { useEffect, useState } from 'react'

import { colors } from '../utils'
import Fond05 from '../svg/fond_05.svg'
import Tab1 from '../svg/tab1.svg';
import Tab2 from '../svg/tab2.svg';
import Switch from "react-switch";

const aliases = {
  'CP': 'Primaire',
}

export default ({ dims }) => {
  const [ levels, setLevels ] = useState([]);
  const [ parentPrice, setParentPrice ] = useState([
      14.71,
      17.49,
      17.49,
      20.24,
      20.24,
      22.12,
      24.88,
      26.74,
      35.96
  ]);
  const [ parentRemotePrice, setParentRemotePrice ] = useState([
      12.87,
      14.71,
      14.71,
      17.49,
      17.49,
      19.33,
      22.12,
      23.96,
      31.35
  ]);
  const [remote, setRemote] = useState(false);
  // const [ passes, setPasses ] = useState([]);

  const fetchData = async () => {
    try {
      const levelsRes = await fetch('https://api.expliquemoipythagore.fr/v2/public/levels');
      let levels = await levelsRes.json();
      levels = levels
      .map(l => {
        if (['CE1', 'CE2', 'CM1', 'CM2'].includes(l.name)) {
          return null
        }
        return { ...l, name: aliases[l.name] || l.name }
      })
      .filter(l => l !== null)
      setLevels(levels.reverse());
      // const passesRes = await fetch('https://api.expliquemoipythagore.fr/v2/public/passes');
      // const passes = await passesRes.json();
      // setPasses(passes);
    } catch (err) {
      console.error('Error fetching levels', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  /*
  const goToSignup = () => {
    window.open('https://app.expliquemoipythagore.fr/register', '_blank');
  }
  */

  return (
    <div id="tarifs" style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: dims.x > 1200 ? 50 : 0, paddingBottom: '15%', backgroundColor: dims.x > 1200 ? 'transparent' : '#D9E9F4' }}>
      <div style={{ width: '100%', maxWidth: 1200, height: '70vh', position: 'relative', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', justifyContent: dims.x > 1200 ? 'space-between' : 'flex-start', alignItems: dims.x > 1200 ? 'flex-start' : 'center', marginTop: dims.x > 1200 ? 200 : 50 }}>
        <div style={{ marginLeft: dims.x > 1200 ? 50 : 0, width: dims.x > 1200 ? 370 : '100%', display: 'flex', flexDirection: 'column', justifyContent: dims.x > 1200 ? 'flex-start' : 'center' }}>
          <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, fontFamily: 'poppins', textAlign: dims.x > 1200 ? 'left' : 'center' }}>NOS TARIFS</h2>
          <p style={{ fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, textAlign: dims.x > 1200 ? 'left' : 'center' }}>Payer un cours moins cher que la rémunération du professeur ?</p>
          <p style={{ fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, textAlign: dims.x > 1200 ? 'left' : 'center' }}>Régler 22,12 € l’heure d’un cours en seconde alors que le professeur en touchera 25€ pour ce même cours ?</p>
          <p style={{ fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, textAlign: dims.x > 1200 ? 'left' : 'center' }}>C’est possible grâce à l’avance immédiate de crédit d’impôt du CESU et son service CESU+.</p>
          <p style={{ fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, textAlign: dims.x > 1200 ? 'left' : 'center' }}>Afin de garantir à nos professeurs une rémunération équitable, nous ne prenons aucune commission sur le prix du cours. Ainsi, vous réglez directement le cours auprès du professeur à la fin de la séance ou via le service CESU+.</p>
          <p style={{ fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 16, textAlign: dims.x > 1200 ? 'left' : 'center' }}> Partenariat PEEP St Maur - tarifs préférentiels pour les adhérents PEEP - renseignez vous sur leur <a href="https://secondaire.peepsaintmaur.fr/fr/pourquoi-adherer">site</a></p>
        </div>
        {/*Tableau*/}
        <div style={{display: 'flex', justifyContent: 'right', flexDirection: dims.x > 1200 ? 'row' : 'column'}}>
          <div style={{marginRight: dims.x > 1200 ? '-15%' : 0, display: 'flex', alignItems: 'center'}}>
            <p style={{ color: colors.dark_blue, fontWeight: 'bold', fontFamily: 'Poppins', margin: 0, marginRight: 15 }}>à domicile</p>
              <Switch checkedIcon={null} uncheckedIcon={null} handleDiameter={20} activeBoxShadow={'0 0 0px'} height={5} width={50} offHandleColor={colors.skin} onHandleColor={colors.blue} offColor={'#dddddd'} onColor={'#dddddd'} checked={remote} onChange={(v) => {console.log('v', v); setRemote(v) }}/>
            <p style={{ color: colors.dark_blue, fontWeight: 'bold', fontFamily: 'Poppins', margin: 0 , marginLeft: 15 }}>à distance</p>
          </div>
          <div style={{ width: dims.x > 1200 ? 500 : '85%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', position: dims.x > 1200 ? 'static' : 'absolute', right: '5%', marginTop: dims.x > 1200 ? 0 : 50 }}>
            <div style={{ width: '70%', height: dims.x > 1200 ? 100 : 100, display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
              <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: remote ? colors.blue : colors.skin, border: colors.white, borderBottomStyle: 'solid', borderWidth: 2 }}>
                  <h3 style={{ textAlign: 'center', color: colors.white, fontWeight: 'bold', fontFamily: 'Poppins', textTransform: 'uppercase', padding: dims.x > 1200 ? 15 : 10, fontSize: dims.x > 1200 ? 20 : 12, margin: 0, }}>{remote ? "à distance" : "à domicile"}</h3>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', }}>
                  <h4 style={{ textAlign: 'center', width: '50%', color: colors.white, fontFamily: 'Poppins', textTransform: 'uppercase', backgroundColor: remote ? colors.blue : colors.skin, padding: dims.x > 1200 ? 10 : 10, paddingInline: 5, fontWeight: 'normal', fontSize: dims.x > 1200 ? 14 : 12, margin: 0 }}>Rémunération professeur</h4>
                  <h4 style={{ textAlign: 'center', width: '50%', color: colors.white, fontFamily: 'Poppins', textTransform: 'uppercase', backgroundColor: remote ? colors.blue : colors.skin, padding: dims.x > 1200 ? 10 : 10, paddingInline: 5, fontWeight: 'normal', fontSize: dims.x > 1200 ? 14 : 12, margin: 0, marginLeft: '1%' }}>Coût parent</h4>
                </div>
                <div>
                {!!levels && levels.map((l, i) => (
                  <div key={l._id} style={{ width: '100%', height: 50, backgroundColor: "#0000", display: 'flex', flexDirection: 'row', marginBottom: (i === 0 || i === 4 || i === 7) ? 2 : 0 }}>
                    <div style={{ height: 50, width: '50%', marginLeft: '-50%', backgroundColor: colors.dark_blue, display: 'flex', alignItems: 'center', color: colors.white, fontFamily: 'Poppins', fontWeight: 'bold', textTransform: 'uppercase', fontSize: dims.x > 1200 ? 22 : 14, paddingLeft: dims.x > 1200 ? 25 : '5%' }}>{l.name}</div>
                    {/*<div style={{ width: 0, height: 0, borderTop: '25px solid transparent', borderBottom: '25px solid transparent', borderLeft: `25px solid ${l.special ? '#DF8260' : colors.dark_blue}`, zIndex: 2 }}></div>*/}
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'right', flexDirection: 'row', marginRight: 0}}>
                      <div style={{ width: '50%', display: 'flex', backgroundColor: colors.white, alignItems: 'center', justifyContent: 'center', color: colors.black, fontFamily: 'Poppins', textTransform: 'uppercase', fontSize: 20}}>{remote ? l.remotePrice : l.price} €</div>
                      <div style={{ width: '0.5%', backgroundColor: colors.white, display: 'flex', alignItems: 'center'}}>
                        <div style={{ backgroundColor: colors.gray, width: '100%', height: '50%'}}/>
                      </div>
                      <div style={{ width: '50%', display: 'flex', backgroundColor: colors.white, alignItems: 'center', justifyContent: 'center', color: colors.black, fontFamily: 'Poppins', textTransform: 'uppercase', fontSize: 20}}>{remote ? parentRemotePrice[i] : parentPrice[i]} €</div>
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {dims.x > 1200 && <div style={{ position: 'absolute', bottom: -7, right: 50, zIndex: -1 }}>
          <Tab1 />
        </div>}
      </div>
      <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column-reverse', justifyContent: 'space-between', alignItems: 'center', marginTop: dims.x < 1200 ? 260 : 115}}>
        <div style={{ width: dims.x > 1200 ? 'auto' : '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/*   !!passes && passes.map((l, i) => (
            <div key={l.price} style={{ width: '100%', height: 50, backgroundColor: colors.white, display: 'flex', alignItems: 'center', marginBottom: (i === passes.length - 1) ? 0 :  1 }}>
              <div style={{ height: '100%', width: dims.x > 1200 ? 230 : '50%', backgroundColor: l.special ? '#DF8260' : colors.dark_blue, display: 'flex', alignItems: 'center', color: colors.white, fontFamily: 'Poppins', textTransform: 'uppercase', fontSize: dims.x > 1200 ? 25 : 14, paddingLeft: 20 }}>PASS {l.duration} mois</div>
              <div style={{ width: 0, height: 0, borderTop: '25px solid transparent', borderBottom: '25px solid transparent', borderLeft: `25px solid ${l.special ? '#DF8260' : colors.dark_blue}`, zIndex: 2 }}></div>
              <div style={{ height: '100%', width: dims.x > 1200 ? 150 : '50%', backgroundColor: l.special ? '#F1BB94' : 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', color: l.special ? colors.white : colors.black, fontFamily: 'Poppins', fontWeight: 'bold', textTransform: 'uppercase', fontSize: dims.x > 1200 ? 25 : 14, marginLeft: -25, paddingLeft: 25, zIndex: 1 }}>{l.price / 100} €</div>
            </div>
          ))*/}

          <div key={"sans_engagement"} style={{ width: '100%', height: dims.x > 1200 ? 70 : 50, backgroundColor: colors.white, display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <div style={{ height: '100%', width: dims.x > 1200 ? 300 : '50%', backgroundColor: colors.dark_blue, display: 'flex',
              alignItems: 'center', color: colors.white, fontFamily: 'Poppins', textTransform: 'uppercase', fontSize: dims.x > 1200 ? 22 : 13, paddingLeft: 20 }}>
              Sans engagement
            </div>
            <div style={{ width: 0, height: 0, borderTop: `${dims.x > 1200 ? 35 : 25}px solid transparent`, borderBottom: `${dims.x > 1200 ? 35 : 25}px solid transparent`, borderLeft: `${dims.x > 1200 ? 35 : 25}px solid ${colors.dark_blue}`, zIndex: 2 }}/>
            <div style={{ height: '100%', width: dims.x > 1200 ? 200 : '50%', backgroundColor: 'transparent', display: 'flex',
              alignItems: 'center', justifyContent: 'center', color: colors.black, fontFamily: 'Poppins', fontWeight: 'bold',
              fontSize: dims.x > 1200 ? 25 : 14, marginLeft: -25, paddingLeft: 25, zIndex: 1 }}>
              25 € / MOIS
            </div>
          </div>

          <div key={"engagement"} style={{ width: '100%', height: dims.x > 1200 ? 70 : 50, backgroundColor: colors.white, display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <div style={{ height: '100%', width: dims.x > 1200 ? 300 : '50%', backgroundColor: colors.dark_blue, display: 'flex',
              alignItems: 'center', color: colors.white, fontFamily: 'Poppins', textTransform: 'uppercase', fontSize: dims.x > 1200 ? 22 : 13, paddingLeft: 20 }}>
              Engagement 10 mois + 2 offerts
            </div>
            <div style={{ width: 0, height: 0, borderTop: `${dims.x > 1200 ? 35 : 25}px solid transparent`, borderBottom: `${dims.x > 1200 ? 35 : 25}px solid transparent`, borderLeft: `${dims.x > 1200 ? 35 : 25}px solid ${colors.dark_blue}`, zIndex: 2 }}/>
            <div style={{ height: '100%', width: dims.x > 1200 ? 200 : '50%', backgroundColor: 'transparent', display: 'flex',
              alignItems: 'center', justifyContent: 'center', color: colors.black, fontFamily: 'Poppins', fontWeight: 'bold',
              fontSize: dims.x > 1200 ? 25 : 14, marginLeft: -25, paddingLeft: 25, zIndex: 1 }}>
              15 € / MOIS
            </div>
          </div>
        </div>
        <div style={{ marginRight: dims.x > 1200 ? 67 : 0, width: dims.x > 1200 ? 300 : '80%', marginTop: dims.x > 1200 ? 40 : 350 - dims.x / 8}}>
          <p style={{ textAlign: dims.x > 1200 ? 'right' : 'center', fontFamily: 'poppins', fontWeight: 'bold', color: colors.dark_blue , fontSize: dims.x > 1200 ? 20 : 16 }}>Pour vous permettre de vous assurer de la qualité et du sérieux de notre service, <a style={{color: colors.green}}>nous vous offrons la première mise en relation.</a> La souscription à un abonnement sera nécessaire pour continuer à prendre des cours via notre service.</p>
        </div>
        {dims.x > 1200 && <div style={{ position: 'absolute', bottom: -7, left: 65, zIndex: -1 }}>
          <Tab2 />
        </div>}
      </div>
      <a href="https://app.expliquemoipythagore.fr/register" target="_blank" style={{ width: dims.x > 1200 ? 400 : '80%', height: dims.x > 1200 ? 60 : 60, backgroundColor: colors.blue, display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginTop: dims.x > 1200 ? 60 : 60 }}>
        <p style={{ margin: 0, color: colors.white, fontWeight: 450, fontFamily: 'poppins', fontSize: dims.x > 1200 ? 25 : 20, textAlign: 'center' }}>COMMANDER UN COURS</p>
      </a>
      {dims.x > 1200 && <div style={{ width: '100%', position: 'absolute', right: 0, top: 0, zIndex: -2 }}>
        <Fond05/>
      </div>}
    </div>
  )
}
